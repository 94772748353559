import Header from '../components/Header'
import Footer from '../components/Footer'

import point from '../assets/icons/point.svg'
import introImage1 from '../assets/images/introImage1.png'
import introImage2 from '../assets/images/introImage2.png'
import introImage3 from '../assets/images/introImage3.png'
import introImage4 from '../assets/images/introImage4.png'
import introImage5 from '../assets/images/introImage5.png'
import introImage6 from '../assets/images/introImage6.png'
import introImage7 from '../assets/images/introImage7.png'
import introImage8 from '../assets/images/introImage8.png'
import introImage9 from '../assets/images/introImage9.png'
import introImage10 from '../assets/images/introImage10.png'
import introImage11 from '../assets/images/introImage11.png'

function Intro() {
  return (
    <div className='w-full'>
      <Header />
      <div className='w-full flex justify-center items-center'>
        <div className='w-full flex flex-col items-center justify-between gap-[70px] sm:gap-[100px] max-lg:px-[20px] mt-[50px] mb-[50px]'>
          {/* body1 */}
          <div className='w-full container mx-auto flex items-start'>
            <div className='flex flex-col justify-between gap-[20px] sm:gap-[40px]'>
              <div className='text-h1-semibold sm:text-display1-bold text-default'>
                <p>
                  원하시는 <span className='text-main'>최상의</span>{' '}
                  <br className='max-sm:hidden' />
                  <span className='text-main'>공간</span>을 구현합니다
                </p>
              </div>
              <div className='max-w-[453px] text-default text-body2-long-medium sm:text-body1-long-medium'>
                <p>
                  GOOD SPACE는 고객의 필요에 맞춘{' '}
                  <span className='text-main text-body2-long-semibold sm:text-body1-long-semibold'>
                    친환경적인 공간
                  </span>
                  을 설계하고 구현하는 전문 기업입니다.
                  <br />
                  공간 기획부터 구축 및 관리까지, 포괄적인 서비스를 통해 고객에게{' '}
                  <span className='text-main text-body2-long-semibold sm:text-body1-long-semibold'>
                    최상의 공간
                  </span>
                  을 제공합니다.
                </p>
              </div>
            </div>
          </div>
          {/* body 2 */}
          <div className='w-full flex flex-col justify-center items-center gap-[20px] sm:gap-[40px]'>
            <div className='w-full container mx-auto flex flex-col justify-center gap-[35px] md:gap-[70px]'>
              <p className='flex justify-start text-default text-title3-bold sm:text-title1-bold'>
                굿스페이스 주요 사업 분야
              </p>
              <div className='w-full text-main text-h2-semibold xl:text-title3-medium flex justify-between items-center max-md:hidden'>
                <p>친환경 부스 시스템</p>
                <img src={point} alt='' />
                <p>건물 내외부 pop 디자인</p>
                <img src={point} alt='' />
                <p>공간 환경 개선 사업</p>
                <img src={point} alt='' />
                <p>친환경 물품, 서비스 구현</p>
                <img src={point} alt='' />
                <p>시설 관리</p>
              </div>
              {/* 친환경 부스 시스템 */}
              <div className='w-full flex gap-[20px] sm:gap-[40px] justify-center items-end max-xl:flex-col max-xl:items-center max-md:items-start'>
                <img src={introImage1} alt='' />
                <div className='flex flex-col gap-[20px] sm:gap-[40px] max-xl:flex-col-reverse'>
                  <div className='flex flex-col gap-[8px] sm:gap-[16px] items-start max-md:justify-start'>
                    <p className='text-h2-semibold sm:text-title3-bold text-main'>
                      친환경 부스 시스템
                    </p>
                    <p className='text-body2-medium sm:text-body1-medium text-default'>
                      부스내 실내 공기질을 점검하고 개선하는 스마트 시스템
                    </p>
                    <p className='text-body2-medium sm:text-body1-medium text-default'>
                      밀폐 공간에서 편하게 머무를 수 있는 쾌적한 부스 환경 조성
                    </p>
                  </div>
                  <div className='w-full flex gap-[20px] sm:gap-[40px] max-md:hidden'>
                    <img src={introImage2} alt='' />
                    <img src={introImage3} alt='' />
                  </div>
                </div>
              </div>
            </div>
            {/* 공간 환경 개선 사업 */}
            <div className='w-full flex gap-[20px] sm:gap-[40px] items-start sm:items-end justify-end max-xl:flex-col-reverse max-xl:justify-center'>
              <div className='flex flex-col gap-[8px] sm:gap-[16px] itmes-start sm:items-end'>
                <p className='text-h2-semibold sm:text-title3-bold text-main'>
                  공간 환경 개선 사업
                </p>
                <p className='text-body2-medium sm:text-body1-medium text-default'>
                  건물 내부 환경 개선 작업 및 부식 부분 특수 크리닝
                </p>
                <p className='text-body2-medium sm:text-body1-medium text-default'>
                  위생적인 환경 제공을 위한 소독, 향균 처리
                </p>
              </div>
              <img src={introImage4} alt='' />
            </div>

            <div className='container mx-auto w-full flex flex-col gap-[20px] sm:gap-[40px] items-start justify-center'>
              <div className='flex gap-[20px] sm:gap-[40px]'>
                <div className='flex gap-[20px] sm:gap-[40px] justify-center items-end max-xl:flex-col max-xl:items-start'>
                  <img src={introImage5} alt='' />
                  <div className='flex flex-col gap-[8px] sm:gap-[16px] items-start max-xl:hidden mr-[100px]'>
                    <p className='text-h2-semibold sm:text-title3-bold text-main'>
                      건물 내외부 pop 디자인
                    </p>
                    <p className='text-body2-medium sm:text-body1-medium text-default'>
                      공간별 아트로드 조성 (계단·건물진입로·엘레베이터 등)
                    </p>
                    <p className='text-body2-medium sm:text-body1-medium text-default'>
                      시트지, 물품 활용 건물별 공간 디자인 조성
                    </p>
                  </div>
                </div>
                <div className='flex flex-col gap-[20px] sm:gap-[40px]'>
                  <img src={introImage6} alt='' />
                  <img src={introImage7} alt='' />
                </div>
              </div>
              <div className='flex flex-col gap-[8px] sm:gap-[16px] items-start xl:hidden'>
                <p className='text-h2-semibold sm:text-title3-bold text-main'>
                  건물 내외부 pop 디자인
                </p>
                <p className='text-body2-medium sm:text-body1-medium text-default'>
                  공간별 아트로드 조성 (계단·건물진입로·엘레베이터 등)
                </p>
                <p className='text-body2-medium sm:text-body1-medium text-default'>
                  시트지, 물품 활용 건물별 공간 디자인 조성
                </p>
              </div>
            </div>
            {/* 친환경 물품, 서비스 구현 */}
            <div className='w-full flex gap-[20px] sm:gap-[40px] items-end justify-start max-xl:flex-col max-xl:items-start'>
              <img src={introImage8} alt='' />
              <div className='flex flex-col gap-[8px] sm:gap-[16px] items-start'>
                <p className='text-h2-semibold sm:text-title3-bold text-main'>
                  친환경 물품, 서비스 구현
                </p>
                <p className='text-body2-medium sm:text-body1-medium text-default'>
                  Eco-friendly 물품을 통한 실내외 친환경 조경 공간 조성
                </p>
                <p className='text-body2-medium sm:text-body1-medium text-default'>
                  공간 활용 친환경 캠페인 등 친환경 서비스 제공
                </p>
              </div>
            </div>
            {/* 시설 관리 */}
            <div className='xl:w-full container flex gap-[20px] sm:gap-[40px] items-end justify-end xl:flex-row-reverse max-lg:grid max-lg:grid-cols-2 max-xl:grid max-xl:grid-cols-3'>
              <img src={introImage9} alt='' />
              <img src={introImage10} alt='' />
              <img src={introImage11} alt='' />
              <div className='flex flex-col gap-[8px] sm:gap-[16px] items-end lg:col-start-3'>
                <p className='text-h2-semibold sm:text-title3-bold text-main'>시설 관리</p>
                <p className='text-body2-medium sm:text-body1-medium text-default'>
                  시설물 유지보수 및 공간 설비 관리
                </p>
                <p className='text-body2-medium sm:text-body1-medium text-default'>
                  안전디자인 및 관리를 통한 원활한 시설 운영 지원
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Intro
