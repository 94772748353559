import { useState } from 'react'

import Header from '../components/Header'
import Footer from '../components/Footer'

import mainImage1 from '../assets/images/mainImage1.png'
import mainImage2 from '../assets/images/mainImage2.png'
import mainImage3 from '../assets/images/mainImage3.png'
import mainImage4 from '../assets/images/mainImage4.png'
import polygon from '../assets/icons/polygon.svg'
import point from '../assets/icons/point.svg'

function Main() {
  const [showOption, setShowOption] = useState(false)
  const [currentValue, setCurrentValue] = useState('문의 유형 선택')
  const [data, setData] = useState({})

  const handleClick = () => {
    setShowOption((prev) => !prev)
  }

  const closeOption = () => {
    setShowOption(false)
  }

  const handleCurrentValue = (e) => {
    const { innerText } = e.target
    setCurrentValue(innerText)
    setData(innerText)
    setShowOption(false)
  }

  const onChangeData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
    console.log(data)
  }
  return (
    <div className='w-full'>
      <Header />
      <div className='flex items-center justify-center'>
        <div className=' container mx-auto flex flex-col items-center justify-between gap-[70px] sm:gap-[150px] px-[20px] mt-[50px] mb-[50px]'>
          {/* body1 */}
          <div className='flex items-start w-full'>
            <div className='flex flex-col justify-between gap-[20px] sm:gap-[40px]'>
              <div className='text-h1-semibold sm:text-display1-bold text-default'>
                <p>
                  <span className='text-main'>친환경적인 공간</span>을{' '}
                  <br className='max-sm:hidden' />
                  만듭니다
                </p>
              </div>
              <div className='max-w-[453px] text-default text-body2-long-medium sm:text-body1-long-medium'>
                <p>
                  오늘날 많은 도시와 생활공간에서 공기질 저하, 스트레스 증가 및 환경 오염 문제가
                  심각해지고 있습니다.
                  <br />
                  이러한 문제를 해결하기 위해 GOODSPACE는 친환경적인 공간 조성을 통해{' '}
                  <span className='text-main text-body2-long-semibold sm:text-body1-long-semibold'>
                    건강하고 쾌적한 환경
                  </span>
                  을 제공합니다.
                  <br />
                  GOODSPACE와 함께하는 공간, 환경 개선 사업은{' '}
                  <span className='text-main text-body2-long-semibold sm:text-body1-long-semibold'>
                    지속 가능한 설계
                  </span>
                  와{' '}
                  <span className='text-main text-body2-long-semibold sm:text-body1-long-semibold'>
                    친환경적인 기술
                  </span>
                  로, 양질을 공간을 만들고 있습니다.
                </p>
              </div>
            </div>
          </div>
          {/* body2 */}
          <div className='w-full flex md:h-[800px] lg:h-[900px] xl:h-[1000px] 2xl:h-[1100px]'>
            <div className='w-full h-full flex flex-col max-md:gap-[10px] md:gap-[16px]'>
              <div className='flex flex-col justify-center items-end md:relative md:top-[78px]'>
                <p className='text-default text-h2-semibold sm:text-h1-semibold'>
                  Creating an Eco-Friendly Space
                </p>
                <p className='text-main text-h1-semibold sm:text-title1-bold'>
                  친환경적인 GOOD SPACE
                </p>
              </div>
              <div className='w-full flex items-center gap-[40px] max-md:flex-col'>
                {/* 디자인 설계 */}
                <div className='flex flex-col md:items-start gap-[10px] md:gap-[16px]'>
                  <img src={mainImage1} alt='' />
                  <div className='text-main text-h2-semibold sm:text-title3-bold'>
                    <p className='flex gap-[10px] md:gap-[16px] items-center'>
                      디자인
                      <img src={point} alt='' />
                      설계
                    </p>
                  </div>
                  <p className='text-body2-long-medium sm:text-body1-long-medium text-default text-wrap'>
                    고객의 요구사항을 반영하여,{' '}
                    <span className='text-body2-long-semibold sm:text-body1-long-semibold text-main'>
                      기능적이면서도 미학적으로 뛰어난
                    </span>{' '}
                    공간을 디자인합니다. 환경을 고려한 지속가능한 솔루션을 통해, 쾌적하고 건강한
                    공간을 만듭니다.
                  </p>
                </div>
                {/* 시공 구축 */}
                <div className='flex flex-col md:items-start gap-[10px] md:gap-[16px] md:relative md:top-[134px]'>
                  <img src={mainImage2} alt='' />
                  <div className='text-main text-h2-semibold sm:text-title3-bold'>
                    <p className='flex gap-[10px] md:gap-[16px] items-center'>
                      시공
                      <img src={point} alt='' />
                      구축
                    </p>
                  </div>
                  <p className='text-body2-long-medium sm:text-body1-long-medium text-default'>
                    전문 인력이 직접 공간을 설치하고 구축하는 높은 품질의 시공을 보장합니다.
                    친환경적인 방식과 자재를 사용하여, 고객의 니즈에 최적화된 작업 수행을
                    보장합니다.
                  </p>
                </div>
                <div>
                  {/* 유지보수 관리 */}
                  <div className='flex flex-col md:items-start gap-[10px] md:gap-[16px] md:relative md:top-[256px]'>
                    <img src={mainImage3} alt='' />
                    <div className='text-main text-h2-semibold sm:text-title3-bold'>
                      <p className='flex gap-[10px] md:gap-[16px] items-center'>
                        유지보수
                        <img src={point} alt='' />
                        관리
                      </p>
                    </div>
                    <p className='text-body2-long-medium sm:text-body1-long-medium text-default'>
                      공간이 완성된 후, 필요한 기자재와 물품을 세팅하여 최적의 환경을 제공합니다.
                      공간 유지보수부터 시설관리까지 맞춤형 공간 관리 서비스를 제공합니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* body3 */}
          <div className='w-full flex flex-col justify-center items-center gap-[30px]'>
            <div className='flex flex-col items-center justify-center text-title3-bold sm:text-title1-bold text-default'>
              <p>
                <span className='text-main'>GOODSPACE</span>와 함께
              </p>
              <p>아름답고 건강한 공간을 조성하세요!</p>
            </div>
            <img src={mainImage4} alt='' />
            <div className='flex flex-col items-center text-default text-body1-medium md:text-title2-medium'>
              <p>
                GOODSPACE는{' '}
                <span className='text-main text-body1-semibold md:text-title2-bold'>
                  친환경 공간 조성
                </span>
                의 모든 과정을 책임지며
              </p>
              <p>
                고객과 함께{' '}
                <span className='text-main text-body1-semibold md:text-title2-bold'>
                  지속가능한 미래
                </span>
                를 위해 함께 나아가겠습니다.
              </p>
            </div>
          </div>
          {/* body4 */}
          <div
            onClick={showOption === true ? closeOption : null}
            className='w-full flex flex-col items-center gap-[40px]'
          >
            <p className='flex justify-center text-default text-h1-bold sm:text-title2-bold md:text-title1-bold'>
              <span className='text-main'>GOODSPACE</span>와 프로젝트를 시작해보세요
            </p>
            <div className='flex justify-between w-full'>
              <div className='w-[130px] flex flex-col gap-[40px] sm:gap-[82px] sm:shrink-0 mt-[10px] sm:mt-[21px]'>
                <p className='text-h2-semibold sm:text-title2-bold'>문의 유형</p>
                <div className='flex flex-col gap-[54px] sm:gap-[93px]'>
                  <p className='text-h2-semibold sm:text-title2-bold'>문의 제목</p>
                  <p className='text-h2-semibold sm:text-title2-bold'>문의 내용</p>
                </div>
              </div>
              <div className='w-full flex flex-col gap-[20px] sm:gap-[40px]'>
                {/* 문의 유형 */}
                <div className='relative z-10 max-w-[453px] text-gray001 text-h2-semibold sm:text-title2-medium  bg-gray002'>
                  <img
                    onClick={handleClick}
                    className={`${
                      showOption ? 'rotate-180' : ''
                    } absolute z-20 top-[15px] right-[15px] sm:top-[30px] sm:right-[31px] max-sm:w-[15px] transition-all duration-300`}
                    src={polygon}
                    alt=''
                  />
                  <div
                    onClick={handleClick}
                    className='relative w-full max-w-[453px] px-[15px] py-[10px] sm:px-[31px] sm:py-[21px] text-gray001 text-h2-semibold sm:text-title2-medium'
                    style={{
                      backgroundColor: '#00000020',
                      'box-shadow': '0px 0px 3px 1px rgba(0, 0, 0, 0.20) inset',
                    }}
                  >
                    <label>{currentValue}</label>
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className='overflow-hidden left-0 top-[46px] sm:top-[80px] absolute w-full px-[5px] py-[8px]'
                    >
                      <ul
                        className={`${
                          showOption ? 'translate-y-0' : '-translate-y-[110%]'
                        } w-full transition-all bg-[#DFDFE0] duration-300  max-w-[453px]  `}
                        style={{
                          'box-shadow': '0px 0px 3px 1px rgba(0, 0, 0, 0.20) inset',
                        }}
                      >
                        <li
                          onClick={handleCurrentValue}
                          className='px-[15px] py-[10px] sm:px-[31px] sm:py-[21px] border-b-[1px] border-solid border-gray001 hover:bg-gray-100'
                          name='option'
                          value='Q'
                        >
                          Q
                        </li>
                        <li
                          onClick={handleCurrentValue}
                          className='px-[15px] py-[10px] sm:px-[31px] sm:py-[21px] border-b-[1px] border-solid border-gray001 hover:bg-gray-100'
                          value='N'
                        >
                          N
                        </li>
                        <li
                          onClick={handleCurrentValue}
                          className='px-[15px] py-[10px] sm:px-[31px] sm:py-[21px] border-solid border-gray001 hover:bg-gray-100'
                          value='A'
                        >
                          A
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* 문의 제목 */}
                <div className='flex flex-col items-end w-full'>
                  <div className='max-w-[950px] w-full flex flex-col gap-[4px]'>
                    <input
                      onChange={onChangeData}
                      id='title'
                      name='title'
                      type='text'
                      maxLength='50'
                      className='w-full  md:h-[80px] flex justify-between bg-gray002 shadow-gray py-[10px] px-[15px] sm:px-[30px] sm:py-[20px] text-h2-semibold md:text-title2-medium text-gray001'
                      style={{
                        backgroundColor: '#00000020',
                        'box-shadow': '0px 0px 3px 1px rgba(0, 0, 0, 0.20) inset',
                      }}
                    />
                    <p className='flex justify-start mr-auto text-gray001 text-body2-medium sm:text-h2-medium'>
                      *제목은 최대 50자까지 입력가능합니다
                    </p>
                  </div>
                </div>
                {/* 문의 내용 */}
                <div className='flex flex-col gap-[20px] sm:gap-[40px] justify-center items-start'>
                  <textarea
                    onChange={onChangeData}
                    id='content'
                    name='content'
                    type='text'
                    className='w-full min-h-[250px] md:h-[842px] p-[10px] sm:p-[20px] flex justify-between bg-transparent  resize-none text-h2-semibold md:text-title2-medium text-gray001'
                    style={{
                      backgroundColor: '#00000020',
                      'box-shadow': '0px 0px 3px 1px rgba(0, 0, 0, 0.20) inset',
                    }}
                  ></textarea>
                  <button
                    onClick={onChangeData}
                    className='w-full text-h2-semibold sm:text-title2-bold text-white bg-main py-[10px] sm:py-[21px]'
                  >
                    <p className=' bg-main'>제출하기</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={showOption === true ? closeOption : null}
        className={`${showOption ? '' : 'hidden'} w-full h-full fixed top-0`}
      />

      <Footer />
    </div>
  )
}

export default Main
