import React from 'react'
import headerLogo from '../assets/logos/headerLogo.svg'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

function Header() {
  const location = useLocation()
  return (
    <div className='flex items-center justify-center w-full '>
      <div className='container w-full mx-auto'>
        <div className='w-full flex justify-between px-[10px] mt-[40px] sm:mt-[87px] mb-[40px]'>
          <Link to='/'>
            <img src={headerLogo} alt='' />
          </Link>
          <ul className='flex flex-col items-end gap-[16px]'>
            <li
              className={`${
                location.pathname === '/' ? 'text-main text-h2-semibold sm:text-h1-semibold' : null
              } text-default text-h2-regular sm:text-h1-regular`}
            >
              <Link to='/'>GOODSPACE</Link>
            </li>
            <li
              className={`${
                location.pathname === '/intro'
                  ? 'text-main text-h2-semibold sm:text-h1-semibold'
                  : null
              } text-default text-h2-regular sm:text-h1-regular`}
            >
              <Link to='/intro'>서비스 소개</Link>
            </li>
            <li
              className={`${
                location.pathname === '/example'
                  ? 'text-main text-h2-semibold sm:text-h1-semibold'
                  : null
              } text-default text-h2-regular sm:text-h1-regular`}
            >
              <Link to='/example'>사례</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Header
