import Header from '../components/Header'
import Footer from '../components/Footer'

import exampleImage1 from '../assets/images/exampleImage1.png'
import exampleImage2 from '../assets/images/exampleImage2.png'
import exampleImage3 from '../assets/images/exampleImage3.png'
import exampleImage4 from '../assets/images/exampleImage4.png'
// import exampleImage5 from '../assets/images/exampleImage5.png'
// import exampleImage6 from '../assets/images/exampleImage6.png'

function Example() {
  return (
    <div className='w-full'>
      <Header />
      <div className='flex items-center justify-center'>
        <div className='w-full container mx-auto flex flex-col items-center justify-between gap-[70px] sm:gap-[100px] px-[20px] mt-[50px] mb-[50px]'>
          {/* body1 */}
          <div className='flex items-start w-full'>
            <div className='flex flex-col justify-between gap-[20px] md:gap-[40px]'>
              <div className='text-h1-semibold sm:text-display1-bold text-default'>
                <p>
                  <span className='text-main'>GOODSPACE</span>들을 <br className='max-sm:hidden' />{' '}
                  소개합니다
                </p>
              </div>
              <div className='max-w-[453px] text-default text-body2-long-medium sm:text-body1-long-medium'>
                <p>GOODSPACE는 고객의 필요에 맞춘 친환경적인 공간을</p>
                <p>설계하고 구현하는 전문 기업입니다.</p>
              </div>
            </div>
          </div>
          <div className='flex flex-col w-full max-md:justify-center max-md:items-center'>
            <div className='flex flex-col gap-[20px] md:gap-[40px] justify-center'>
              <div className='flex gap-[20px] md:gap-[40px] items-end justify-start max-md:flex-col max-md:justify-center max-md:items-start'>
                <img src={exampleImage1} alt='' />
                <div className='flex flex-col gap-[10px] sm:gap-[16px] items-start'>
                  <p className='text-body2-medium sm:text-body1-medium text-default'>
                    친환경 부스 설치 사례
                  </p>
                  <p className='text-h2-semibold sm:text-title3-bold'>
                    오산오색시장 밀폐형 스마트 흡연부스
                  </p>
                </div>
              </div>
              <div className='flex gap-[20px] md:gap-[40px] items-end justify-end max-lg:flex-col-reverse max-lg:justify-center max-lg:items-start'>
                <div className='flex flex-col gap-[10px] sm:gap-[16px] items-end max-lg:items-start'>
                  <p className='text-body2-medium sm:text-body1-medium text-default'>
                    굿스페이스 스마트
                  </p>
                  <p className='text-h2-semibold sm:text-title3-bold'>공기질 모니터링 시스템</p>
                </div>
                <img src={exampleImage2} alt='' />
              </div>
              <div className='flex gap-[20px] md:gap-[40px] items-end justify-start max-lg:flex-col max-lg:justify-center max-lg:items-start'>
                <img src={exampleImage3} alt='' />
                <div className='flex flex-col gap-[10px] sm:gap-[16px] items-start'>
                  <p className='text-body2-medium sm:text-body1-medium text-default'>
                    굿스페이스 스마트 흡연부스
                  </p>
                  <p className='text-h2-semibold sm:text-title3-bold'>자동/원격제어 시스템</p>
                </div>
              </div>
              <div className='flex gap-[20px] md:gap-[40px] items-end justify-end max-md:flex-col-reverse max-md:justify-center max-md:items-start'>
                <div className='flex flex-col gap-[10px] sm:gap-[16px] items-end max-md:items-start'>
                  <p className='text-body2-medium sm:text-body1-medium text-default'>
                    공간환경개선사업
                  </p>
                  <p className='text-h2-semibold sm:text-title3-bold'>건물 내부 위생 크리닝</p>
                </div>
                <img src={exampleImage4} alt='' />
              </div>
              {/* <div className='flex gap-[20px] md:gap-[40px] items-end justify-start max-lg:flex-col max-lg:justify-center max-lg:items-start'>
                <img src={exampleImage5} alt='' />
                <div className='flex flex-col gap-[10px] sm:gap-[16px] items-start'>
                  <p className='text-body2-medium sm:text-body1-medium text-default'>
                    친환경 공간 캠페인
                  </p>
                  <p className='text-h2-semibold sm:text-title3-bold'>
                    OO상인회 방사능 검사 안전관리 캠페인
                  </p>
                </div>
              </div>
              <div className='flex gap-[20px] md:gap-[40px] items-end justify-end max-lg:flex-col-reverse max-lg:justify-center max-lg:items-start'>
                <div className='flex flex-col gap-[10px] sm:gap-[16px] items-end max-lg:items-start'>
                  <p className='text-body2-medium sm:text-body1-medium text-default'>
                    노후 시설 관리
                  </p>
                  <p className='text-h2-semibold sm:text-title3-bold'>
                    상가 외벽 실외기 디자인 타공
                  </p>
                </div>
                <img src={exampleImage6} alt='' />
              </div> */}
              <div className='my-[100px] p-[10px] text-center'>
                <p className='mb-[50px] text-title3-bold'>친환경적인 스마트한 공간, 건강한 미래</p>
                <p className='text-main text-title1-bold'>
                  "환경을 생각하는 공간 혁신, 굿스페이스와 함께하세요"
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Example
