import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Main from './views/Main'
import Intro from './views/Intro'
import Example from './views/Example'

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/intro' element={<Intro />} />
        <Route path='/example' element={<Example />} />
      </Routes>
    </Router>
  )
}

export default App
